import HttpClient from './http-clients/portaljad'

const baseUrl = 'clientes'

export default class ClienteService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(clienteId) {
    try {
      if (!clienteId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async listaUnidades(documento) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'lista-unidades', documento].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async listaUnidadesPrime(usuarioId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'lista-unidades-prime', usuarioId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async mudaUnidade(tenant, usuarioId, documento) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'muda-unidade', tenant, usuarioId, documento].join('/'))
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async findServicoEmailById(clienteId) {
    try {
      if (!clienteId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, 'servico-email', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}