<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header class="p-2">
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Imprimir Reversas
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="p-2">
            <b-row>
              <b-col
                md="5"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange"/>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Situação"
                  rules="required"
                >
                  <b-form-group
                    label="Situação"
                    label-for="situacaoId"
                  >
                    <v-select
                      id="situacaoId"
                      v-model="situacoes"
                      multiple
                      :options="situacaoOptions"
                      :reduce="val=>val.value"
                      :clearable="true"
                    />
                    <span class="undermessage-error">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
              >
                <div class="p-2">

                  <b-row class="flex-row-reverse">
                    <div style="display:flex; align-items: center; justify-content: center">
                      <b-button
                        variant="primary"
                        class="mb-sm-0 mr-0 mr-sm-1"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        type="submit"
                      >
                        Pesquisar
                        <feather-icon
                          class="ml-1"
                          icon="SearchIcon"
                        />
                      </b-button>
                    </div>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      v-show="showResultados"
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="TagIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Resultados
          </span>
        </b-card-title>
        <b-col
          md="3"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            class="px-3"
            @click="gerarExcel"
          >
            <span class="text-nowrap">Exportar Excel</span>
          </b-button>
        </b-col>
      </b-card-header>
      <hr class="my-0">
      <div class="m-2">
        <b-row>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
                debounce="1000"
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="changePerPage"
            />
            <label>movimentações por página</label>
          </b-col>
          <b-col
            cols="12"
            class="mt-1"
          >
            <span>
              {{ qtdItemSelecionado() }} movimentações selecionadas
            </span>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        primary-key="movimentacaoId"
        show-empty
        :empty-text="carregando ? 'Carregando...' : 'Nenhum resultado encontrado.'"
        :items="dados.lista"
        :fields="tableColumns"
        sticky-header
        :no-local-sorting="true"
        @sort-changed="sortingChanged"
      >
        <template #head(select)="{}">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-model="allSelected"
              @change="selectAllHandler"
            />
          </div>
        </template>
        <template #cell(select)="data">
          <div class="d-flex justify-content-center">
            <b-checkbox v-model="itemSelecionado(data.item.movimentacaoId).selecionado" />
          </div>
        </template>
        <template #cell(nomeServico)="data">
          {{ serviceDictionary(data.item.nomeServico) }}
        </template>
        <template #cell(situacaoImpressao)="data">
          <b-badge
            :class="`badge badge-light-${data.item.cancelado ? 'light' : data.item.situacaoImpressao === 'IMPRESSO' ? 'success' : 'danger'}`"
          >
            {{ data.item.cancelado ? 'Cancelado' : data.item.situacaoImpressao === 'IMPRESSO' ? 'Impresso' : 'Aguardando Impressão' }}
          </b-badge>
        </template>
        <template #cell(visualizar)="data">
          <b-button
            variant="link"
            @click="openModal(data)"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
            />
          </b-button>
        </template>
      </b-table>
      <hr class="mt-0 mb-1">
      <div class="m-2">
        <b-row v-if="dados && dados.totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, dados.totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, dados.totalRows) }}º de {{
                dados.totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="dados.totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <validation-observer
        #default="{validate}"
        ref="impressaoValidation"
      >
        <div class="p-2 pb-4 d-flex flex-row-reverse align-items-center">
          <b-button
            variant="primary"
            class="ml-2"
            @click="validate().then(imprimirEtiquetas)"
          >
            <span class="text-nowrap">Imprimir Selecionados</span>
          </b-button>
          <b-col
            cols="6"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Tipo Etiqueta"
              rules="required"
            >
              <b-form-group
                label="Tipo Etiqueta"
                label-for="tipoEtiquetaId"
              >
                <v-select
                  id="tipoEtiquetaId"
                  v-model="tiposDeEtiquetas"
                  :options="tiposDeEtiquetasOptions"
                  :reduce="val=>val.value"
                  :clearable="true"
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </div>
      </validation-observer>
    </b-card>
    <b-modal
      ref="modal"
      title="Detalhes"
      ok-title="OK"
      :cancel-variant="modalData.cancelado ? '': 'danger'"
      :cancel-title="modalData.cancelado ? 'Etiqueta Cancelada' : 'Cancelar Etiqueta'"
      :cancel-disabled="modalData.cancelado"
      size="xl"
      style="max-height: 10000px"
      footer-class="d-flex justify-content-between"
      @cancel="cancelarEnvio(modalData)"
    >
      <details-card :data="modalData" />

    </b-modal>
    <b-modal
      ref="modalEtiqueta"
      title="Impressão de Etiqueta"
      ok-only
      ok-title="OK"
      size="xl"
      scrollable
      class="iframe-modal"
      @hidden="fechaModalEtiqueta"
    >
      <b-row>
        <b-col
          cols="12"
          :sm="getDeclaracaoConteudo || getDanfe ? 6 : 12"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrc"
            class="iframe"
          />
        </b-col>
        <b-col
          v-if="getDeclaracaoConteudo"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrcDeclaracaoConteudo"
            class="iframe"
          />
        </b-col>
        <b-col
          v-if="getDanfe"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrcDanfe"
            class="iframe"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import swal from 'sweetalert2/dist/sweetalert2.min'
import DateRange from '@/components/form/DateRange.vue'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import utils from '@/helpers/utils'
import { $themeConfig } from '@themeConfig'
import DetailsCard from '../components/imprimirDetailsReversa'

export default {
  name: 'IndexVue',
  components: { DetailsCard, DateRange },
  setup() {
    const { appCarregando } = $themeConfig.app

    const etiquetaStore = createNamespacedHelpers('imprimir')

    const gerarEtiquetaStore = createNamespacedHelpers('gerarEtiquetas')

    const showResultados = ref(false)

    const modal = ref(null)

    const modalEtiqueta = ref(null)

    const tableRef = ref(null)

    const formatoEtiqueta = localStorage.getItem('formato-etiqueta')

    const state = reactive({
      dateRange: {
        startDate: utils.dateRangeComTimeZone(),
        endDate: utils.dateRangeComTimeZone(),
      },
      dados: etiquetaStore.useGetters(['dados']).dados,
      movimentacaoIds: etiquetaStore.useGetters(['movimentacaoIds']).movimentacaoIds,
      getEtiquetas: gerarEtiquetaStore.useGetters(['gerarEtiquetas']).gerarEtiquetas,
      getDeclaracaoConteudo: gerarEtiquetaStore.useGetters(['gerarDeclaracaoConteudo']).gerarDeclaracaoConteudo,
      getDanfe: gerarEtiquetaStore.useGetters(['gerarDanfe']).gerarDanfe,
      situacoes: ['AGUARDANDO_IMPRESSAO'],
      allSelected: false,
      modalData: {},
      modalEtiqueta: null,
      tiposDeEtiquetas: formatoEtiqueta || 'etiqueta_jadlog_16x10',
      movimentacoesId: [],
      pdfsrc: '',
      pdfsrcDeclaracaoConteudo: '',
      pdfsrcDanfe: '',
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
      search: '',
      order: {},
    })
    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    const sendRequest = obj => {
      state.carregando = true
      store.dispatch('imprimir/findAll', obj).then(() => {
        state.carregando = false
      })
    }

    const imprimirEtiquetasRequest = obj => {
      store.dispatch('gerarEtiquetas/findReversa', obj)
    }

    const imprimirDeclaracaoConteudoRequest = obj => { store.dispatch('gerarEtiquetas/declaracaoConteudo', obj) }

    const imprimirDanfeRequest = obj => { store.dispatch('gerarEtiquetas/danfe', obj) }

    const openEtiquetaModal = data => {
      state.modalEtiqueta = data
      modalEtiqueta.value.show()
    }

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const imprimirEtiquetasRetornoQrCode = etiquetas => {
      appCarregando.show()
      store.dispatch('gerarEtiquetas/gerarEtiquetasReversaQrCode', { etiquetas, clienteId }).then(r => {
        appCarregando.hide()
        openEtiquetaModal(r)
      })
    }

    const table = {
      isSortDirDesc: ref(false),
      tableColumns: [
        { key: 'select', sortable: false },
        { key: 'shippmentId', sortable: true },
        { key: 'nomeServico', sortable: true, label: 'Serviço' },
        { key: 'remetente.nome', sortable: true, label: 'Remetente' },
        { key: 'remetente.cep', sortable: true, label: 'CEP', tdClass: 'text-nowrap' },
        { key: 'remetente.uf', sortable: true, label: 'UF' },
        { key: 'remetente.cidade', sortable: true, label: 'Cidade' },
        { key: 'numeroNotaFiscal', sortable: true, label: 'Nº NF', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'quantidade', sortable: true, label: 'Volumes' },
        { key: 'metodoInsercaoTexto', sortable: true, label: 'Tipo', tdClass: 'text-nowrap' },
        { key: 'situacaoImpressao', sortable: true, label: 'Situação' },
        { key: 'visualizar', sortable: false },
      ],
    }

    const resetDados = () => {
      state.dados.movimentacaoIds = []
      state.currentPage = 1
      state.dados.totalRows = 0
      state.search = ''
      state.order = {}
    }

    const getMovIds = tipoPesquisa => {
      switch (tipoPesquisa) {
        case 'PAGINACAO': return state.dados.movimentacaoIds
        case 'ORDENACAO': return state.dados.movimentacaoIds
        case 'FILTRAGEM': return state.movimentacaoIds
        case 'ATUALIZAR': return state.movimentacaoIds
        default: return []
      }
    }

    const onSubmit = tipoPesquisa => {
      if (tipoPesquisa === 'NOVA_PESQUISA') {
        resetDados()
      }
      const mountObject = {
        movimentacaoIds: getMovIds(tipoPesquisa),
        dataInicial: state.dateRange.startDate,
        dataFinal: state.dateRange.endDate,
        situacao: state.situacoes,
        clienteId,
        reversa: true,
        currentPage: state.currentPage || 1,
        perPage: state.perPage || 10,
        totalRows: state.dados.totalRows || 0,
        filtroGeral: state.search,
        tipoPesquisa: tipoPesquisa || 'NOVA_PESQUISA',
        movimentacaoIdsSelecionados: state.dados.movimentacaoIdsSelecionados,
        order: state.order,
      }
      sendRequest(mountObject)
      showResultados.value = true
    }

    onSubmit('NOVA_PESQUISA')

    const selectAllHandler = bool => {
      store.dispatch('imprimir/selectAll', bool)
    }

    const itemSelecionado = id => state.dados.movimentacaoIdsSelecionados.find(mov => mov.id === id)

    const qtdItemSelecionado = () => {
      if (state.dados.movimentacaoIdsSelecionados) {
        return state.dados.movimentacaoIdsSelecionados.filter(mov => mov.selecionado === true).length
      }
      return 0
    }

    const imprimirEtiquetas = () => {
      appCarregando.show()
      const sortedIds = state.dados.movimentacaoIdsSelecionados.filter(mov => mov.selecionado === true).map(item => item.id)
      const objEtiqueta = {
        etiqueta: sortedIds,
        tipoEtiqueta: state.tiposDeEtiquetas,
        reversa: true,
      }
      const objDeclaracaoConteudo = {
        etiqueta: sortedIds,
        tipoEtiqueta: 'A4',
        reversa: true,
      }
      if (objEtiqueta.etiqueta.length > 0 && objEtiqueta.tipoEtiqueta) {
        appCarregando.hide()
        localStorage.setItem('formato-etiqueta', state.tiposDeEtiquetas)
        if (objEtiqueta.tipoEtiqueta === 'etiqueta_qr_code') {
          imprimirEtiquetasRetornoQrCode(objEtiqueta.etiqueta)
        } else {
          imprimirEtiquetasRequest(objEtiqueta)
          imprimirDeclaracaoConteudoRequest(objDeclaracaoConteudo)
          imprimirDanfeRequest(objEtiqueta)
          openEtiquetaModal(state.modalEtiqueta)
        }
      }
      if (objEtiqueta.etiqueta.length === 0) {
        appCarregando.hide()
        swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          text: 'Informe ao menos uma etiqueta da lista',
          showConfirmButton: false,
          timer: 3000,
        })
      }
    }

    const downloadExcel = () => {
      const ids = state.dados.movimentacaoIdsSelecionados.filter(mov => mov.selecionado === true).map(mov => mov.id)
      if (ids.length === 0) {
        appCarregando.hide()
        swal.fire({
          icon: 'warning',
          title: 'Sem dados para exportar',
          text: 'Informe ao menos uma Etiqueta da lista',
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        const obj = {
          movimentacaoIds: ids, 
          reversa: true,
          tipoPesquisa: 'EXPORTACAO',
        }
        store.dispatch('imprimir/exportar', obj)
          .then(sheetData => {
            if (sheetData.length === 0) {
              appCarregando.hide()
              swal.fire({
                icon: 'warning',
                title: 'Sem dados para exportar',
                text: 'O Resultado da sua busca não retornou dados para exportação.',
                showConfirmButton: false,
                timer: 3000,
              })
            } else {
              sheetData.forEach(s => {
                if (s.dataRecebimentoNotaFiscal) {
                  s.dataProtocoloNotaFiscal = s.dataRecebimentoNotaFiscal
                  delete s.dataRecebimentoNotaFiscal
                }
              })
              /* convert data to worksheet */
              const ws = XLSX.utils.json_to_sheet(sheetData)
              
              /* create new workbook and add the worksheet */
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
            
              /* generate an XLSX file */
              const fileName = 'Relatorio - Reversa.xlsx'
              const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
              
              /* save the file */
              appCarregando.hide()
              FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName)
            }
          })
      }
    }

    const openModal = data => {
      state.modalData = data.item
      modal.value.show()
    }

    watch(
      () => state.getEtiquetas,
      value => {
        state.pdfsrc = value
      },
    )

    watch(() => state.getDeclaracaoConteudo, value => {
      state.pdfsrcDeclaracaoConteudo = value
    })

    watch(() => state.getDanfe, value => {
      state.pdfsrcDanfe = value
    })

    const situacaoOptions = [
      { value: 'AGUARDANDO_IMPRESSAO', label: 'AGUARDANDO IMPRESSÃO' },
      { value: 'IMPRESSO', label: 'IMPRESSO' },
      { value: 'CANCELADA', label: 'CANCELADA' },
    ]

    const tiposDeEtiquetasOptions = [
      { value: 'etiqueta_jadlog_16x10', label: 'Etiqueta 16X10' },
      { value: 'etiqueta_jadlog_10x10', label: 'Etiqueta 10X10' },
      { value: 'etiqueta_jadlog_A4', label: 'Etiqueta 16x10 A4' },
      { value: 'danfe_simplificado_16x10', label: 'Etiqueta 16x10 com Danfe' },
      { value: 'etiqueta_jadlog_mini_danfe_16x10', label: 'Etiqueta 16x10 mais mini Danfe' },
      { value: 'etiqueta_qr_code', label: 'QR Code Reversa' },
    ]

    const gerarExcel = () => {
      appCarregando.show()
      setTimeout(() => {
        downloadExcel()
      }, 100)
    }

    watch(() => state.search, () => {
      state.currentPage = 1
      onSubmit('FILTRAGEM')
    })

    const changePerPage = () => {
      state.currentPage = 1
      onSubmit('PAGINACAO')
    }

    const changePage = value => {
      state.currentPage = value
      onSubmit('PAGINACAO')
    }

    const fechaModalEtiqueta = () => {
      state.pdfsrc = ''
      state.pdfsrcDeclaracaoConteudo = ''
      state.pdfsrcDanfe = ''
      onSubmit('ATUALIZAR')
    }

    const sortingChanged = ctx => {
      state.order = {
        ...ctx,
      }
      if (state.dados && state.dados.totalRows > 1) {
        onSubmit('ORDENACAO')
      }
    }

    const cancelarEnvio = movimentacao => {
      swal.fire({
        icon: 'warning',
        html: 'Deseja realmente cancelar o envio?',
        showConfirmButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        confirmButtonText: 'OK',
        cancelButtonText: 'Voltar',
      }).then(result => {
        if (result.isConfirmed) {
          const movDeletar = {
            shipmentId: movimentacao.shippmentId,
            movimentacaoId: movimentacao.movimentacaoId,
            clienteId,
          }
          appCarregando.show()
          store.dispatch('movimentacao/cancelarMovimento', movDeletar).then(response => {
            if (response.success) {
              appCarregando.hide()
              swal.fire({
                icon: 'success',
                html: '<span>Cancelado com sucesso!</span>',
                showConfirmButton: true,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
              onSubmit()
            } else {
              appCarregando.hide()
              swal.fire({
                icon: 'error',
                html: `<span>${response.body}</span>`,
                showConfirmButton: true,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          })
        }
      })
    }

    return {
      ...toRefs(state),
      ...utils,
      situacaoOptions,
      tableRef,
      ...table,
      selectAllHandler,
      itemSelecionado,
      qtdItemSelecionado,
      modal,
      modalEtiqueta,
      openEtiquetaModal,
      openModal,
      imprimirEtiquetas,
      showResultados,
      onSubmit,
      tiposDeEtiquetasOptions,
      gerarExcel,
      changePerPage,
      changePage,
      fechaModalEtiqueta,
      sortingChanged,
      cancelarEnvio,
    }
  },
}
</script>

<style lang="scss">
.iframe-modal {
  height: 100vh;
}

.iframe {
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
</style>
