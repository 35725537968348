import IntegracaoService from '@/service/IntegracaoService'

export default {
  namespaced: true,
  state: {
    vendas: [],
  },
  getters: {
    vendas(state) {
      return state.vendas
    },
  },
  mutations: {
    UPDATE_VENDAS(state, vendas) {
      state.vendas = vendas
    },
    SELECT_ALL_VENDAS(state, bool) {
      state.vendas = state.vendas.map(venda => ({ ...venda, selected: (venda.importado || venda.situacao === null) ? false : bool }))
    },
  },
  actions: {
    async findVendas({ commit }, filtros) {
      commit('UPDATE_VENDAS', null)
      const dados = await IntegracaoService.findVendas(filtros)
      if (dados.success) {
        commit('UPDATE_VENDAS', dados.body)
      }
      return dados
    },
    async compleatePagination({ commit }, formData) {
      commit('UPDATE_VENDAS', null)
      const dados = await IntegracaoService.compleatePagination(formData)
      if (dados.success) {
        commit('UPDATE_VENDAS', dados.body)
      }
      return dados
    },
    selectAll({ commit }, bool) {
      commit('SELECT_ALL_VENDAS', bool)
    },
  },
}