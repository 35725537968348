import CryptoJS from 'crypto-js'

export default class Utils {
  static removeMask = (value, mask) => {
    const string = String(value)
    const maskCharArray = mask.split('')
    const maskSpecialCharacters = maskCharArray.filter(char => {
      if (
        char === '#'
        || char === 'A'
        || char === 'X'
        || char === 'N'
        || char === '?'
      ) {
        return false
      }
      return true
    })
    let stringCharArray = string.split('')
    stringCharArray = stringCharArray.filter(char => {
      if (maskSpecialCharacters.includes(char)) {
        return false
      }
      return true
    })
    const removedMaskString = stringCharArray.join('')
    return removedMaskString
  }

  static maskCep = value => {
    if (value.includes('-')) {
      return value
    }
    const raw = [value.substring(0, 5), value.substring(5, 8)]
    return `${raw[0]}-${raw[1]}`
  }

  static maskCpf = value => {
    const raw = [value.substring(0, 3), value.substring(3, 6), value.substring(6, 9), value.substring(9, 11)]
    return `${raw[0]}.${raw[1]}.${raw[2]}-${raw[3]}`
  }

  static maskCnpj = value => {
    const raw = [value.substring(0, 2), value.substring(2, 5), value.substring(5, 8), value.substring(8, 12), value.substring(12, 14)]
    return `${raw[0]}.${raw[1]}.${raw[2]}/${raw[3]}-${raw[4]}`
  }

  static maskDate = value => {
    const raw = [value.substring(0, 4), value.substring(5, 7), value.substring(8, 10)]
    return `${raw[2]}/${raw[1]}/${raw[0]}`
  }

  static maskDateHour = value => {
    const dataHour = value.split('T')
    const raw = [dataHour[0].substring(0, 4), dataHour[0].substring(5, 7), dataHour[0].substring(8, 10)]
    return `${raw[2]}/${raw[1]}/${raw[0]} - ${dataHour[1]}`
  }

  static maskHour = value => {
    const dataHour = value.split('T')
    return `${dataHour[1]}`
  }

  static maskHourMin = dateString => {
    const date = new Date(dateString)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}h${minutes}m`
  }

  static maskCurrency = value => value.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })

  static maskDecimal = value => value.toLocaleString('pt-BR', { style: 'decimal' })

  static maskPhone = value => {
    const raw = [value.substring(0, 2), value.substring(2, 7), value.substring(7, 11)]
    return `(${raw[0]}) ${raw[1]}-${raw[2]}`
  }

  static formatDateToPtBr = dateString => {
    const date = new Date(dateString)
    const options = { weekday: 'long', day: 'numeric', month: 'long' }
    const formattedDate = new Intl.DateTimeFormat('pt-BR', options).format(date)
    const capitalizedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    return capitalizedDate
  }

  static encrypt = (data, key) => CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()

  static decrypt = (data, key) => CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8)

  static zeroFill = (number, width) => {
    const zeroFields = width - number.toString().lengt
    if (width > 0) {
      return new Array(zeroFields + (/\./.test(number) ? 2 : 1)).join('0') + number
    }
    return String(number) // always return a string
  }

  static copyToClipboard = (id, isClass) => {
    const range = document.createRange()
    const containerNode = isClass ? document.getElementsByClassName(id)[0] : document.getElementById(id)[0]
    range.selectNode(containerNode)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
  }

  // TESTING
  static generalFilterTable = (tableRow, filter) => {
    const filterFields = filter.split(' & ')
    const matched = Object.values(tableRow).filter(value => {
      const filterMatched = filterFields.filter(field => String(value).toUpperCase().indexOf(field.toUpperCase()) >= 0)
      return filterMatched.length > 0
    })
    return matched.length >= filterFields.length
  }

  static removeSpecialCharacters = valor => {
    const valorAlterado = valor ? valor.replaceAll(/[.,()/\s-]+/g, '') : ''
    return valorAlterado
  }

  static dateRangeComTimeZone = () => {
    const now = new Date()
    const timeZone = now.getTimezoneOffset()
    now.setMinutes(now.getMinutes() - timeZone)
    return now.toISOString().split('T')[0]
  }

  static serviceDictionary = service => {
    const dictionary = {
      EXPRESSO: 'Expresso',
      PACKAGE: 'Package',
      RODOVIARIO: 'Rodoviário',
      RODOVIÁRIO: 'Rodoviário',
      ECONOMICO: 'Econômico',
      ECONÔMICO: 'Econômico',
      DOC: 'DOC',
      CORPORATE: 'Corporate',
      PONTO_COM: '.COM',
      '.COM': '.COM',
      INTERNACIONAL: 'Internacional',
      CARGO: 'Cargo',
      EMERGENCIAL: 'Emergêncial',
      EMERGÊNCIAL: 'Emergêncial',
      PICKUP: 'PickUP',
      'JADLOG-BAG': 'JadlogBag',
      DEFAULT: '',
    }
    return dictionary[service] ? dictionary[service] : dictionary.DEFAULT
  }

  static mesNomes = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

  static diaNomes = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado', 'Domingo']

  static blingV3SituacaoDictionary = service => {
    const dictionary = {
      6: 'EmAberto',
      9: 'Atendido',
      12: 'Cancelado',
      15: 'EmAndamento',
      18: 'VendaAgenciada',
      21: 'EmDigitacao',
      24: 'Verificado',
      DEFAULT: '',
    }
    return dictionary[service] ? dictionary[service] : dictionary.DEFAULT
  }
}