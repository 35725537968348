import ClienteService from '@/service/ClienteService'
import adapter from '@/adapters/ClienteAdapter'

export default {
  namespaced: true,
  state: {
    cliente: null,
    clientes: [],
    unidades: [],
  },

  getters: {
    cliente(state) {
      return state.cliente
    },
    clientes(state) {
      return state.clientes
    },
    unidades(state) {
      return state.unidades
    },
  },
  mutations: {
    SAVE_CLIENTE(state, clientes) {
      state.clientes.push(clientes)
    },
    UPDATE_CLIENTE(state, clientes) {
      state.clientes = clientes
    },
    SET_CLIENTE(state, cliente) {
      state.cliente = cliente
    },
    UPDATE_UNIDADES(state, unidades) {
      state.unidades = unidades
    },

  },
  actions: {
    async save({ commit }, cliente) {
      const clienteId = await ClienteService.save(adapter.toApi(cliente))
      const clienteToSave = { ...cliente, clienteId }
      commit('SAVE_CLIENTE', clienteToSave)
    },
    async findAll({ commit }) {
      const clientes = await ClienteService.findAll()
      commit('UPDATE_CLIENTE', clientes.map(dpto => adapter.toStore(dpto)))
      return clientes
    },
    async findUnidades({ commit }, documento) {
      const unidades = await ClienteService.listaUnidades(documento)
      commit('UPDATE_UNIDADES', unidades)
      return unidades
    },
    async findUnidadesPrime({ commit }, usuarioId) {
      const unidades = await ClienteService.listaUnidadesPrime(usuarioId)
      commit('UPDATE_UNIDADES', unidades)
      return unidades
    },
    async limpaUnidades({ commit }) {
      commit('UPDATE_UNIDADES', [])
    },
    async findById({ commit }, clienteId) {
      const cliente = await ClienteService.findById(clienteId)
      commit('SET_CLIENTE', adapter.toStore(cliente))
      return cliente
    },
  },
}
